import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./../../assets/images/games-icon/leftbar/home.png";
import Cricket from "./../../assets/images/games-icon/leftbar/cricket.png";
import Football from "./../../assets/images/games-icon/leftbar/football.png";
import Tennis from "./../../assets/images/games-icon/leftbar/tennis.png";
import Aviator from "./../../assets/images/games-icon/leftbar/aviator.png";
import HorseRacing from "./../../assets/images/games-icon/leftbar/horse-racing.png";
import GreyhoundRacing from "./../../assets/images/games-icon/leftbar/greyhound-racing.png";
import CardGames from "./../../assets/images/games-icon/leftbar/card-games.png";
import LiveCasino from "./../../assets/images/games-icon/leftbar/live-casino.png";
import Qtech from "./../../assets/images/games-icon/leftbar/qtech.png";
import PremiumSoprtsbook from "./../../assets/images/games-icon/leftbar/premium-soprtsbook.png";
import Exchange from "./../../assets/images/games-icon/leftbar/exchange.png";

import EzugiIcon from "./../../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIcon from "./../../assets/images/games-icon/headicon/table-games-new.svg";
import PromotionIcon from "./../../assets/images/games-icon/Promotion.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            {/* <h6>Quick Links</h6> */}
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={Home} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Cricket")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={Cricket} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div className="sport-event-count badge">
                    {
                      sportsData?.find(
                        ({ sportname }) => sportname === "Cricket"
                      )?.values?.length
                    }
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Soccer")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={Football} alt="Soccer Icon" />
                  <span>Football</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Tennis")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={Tennis} alt=" Icon" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <img src={Aviator} alt="Home Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Horse%20Racing")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={HorseRacing} alt="Home Icon" />
                  <span>Horse Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Greyhound%20Racing")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={GreyhoundRacing} alt="Home Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/cardgames");
                  }}
                >
                  <img src={CardGames} alt="Home Icon" />
                  <span>Card Games</span>
                </div>
              </li>
              <li className="">
                <div
                  onClick={() => {
                    navigate("/casino/ezugi");
                  }}
                  className="no-hover"
                >
                  <img src={LiveCasino} alt="Casino Icon" />
                  {/* <div className="animate-img">
                    <div className="enter-animate active">
                      <div className="spin-anim">
                        <img src={casinoround} className="img-spinbg" />
                        <img src={casinogame} className="img-turntable" />
                        <img src={casinopoint} className="img-pointer" />
                      </div>
                    </div>
                  </div> */}
                  <span>Live Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    window.location.href = "/qtechlobby";
                  }}
                >
                  <img src={Qtech} alt="Home Icon" />
                  <span>Qtech</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby");
                  }}
                >
                  <img src={PremiumSoprtsbook} alt="Home Icon" />
                  <span>Premium Sportsbook</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? navigate("/exchange/Inplay")
                      : (window.location.href = "/beforeexchange");
                  }}
                >
                  <img src={Exchange} alt="Home Icon" />
                  <span>Exchange</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link d-none">
            {/* <h6>Sports</h6> */}
            <ul>
              <li className="d-none">
                <div
                  onClick={() => {
                    navigate("/casino/ezugi");
                  }}
                >
                  <img src={EzugiIcon} alt="Casino Icon" />
                  <span>Ezugi</span>
                </div>
              </li>
              <li className="d-none">
                <div
                  onClick={() => {
                    navigate("/casino/evolution");
                  }}
                >
                  <img src={EvolutionIcon} alt="Casino Icon" />
                  <span>Evolution</span>
                </div>
              </li>
              <li className="d-none">
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={PromotionIcon} alt="Casino Icon" />
                  <span>Promotion</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
