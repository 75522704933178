import React, { PureComponent, useEffect } from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { Link } from "react-router-dom";
import AndarBahar from "../../../../assets/images/static_pages/AndarBaharr.jpg";
import CrickSec2 from "../../../../assets/images/static_pages/fairbet7.jpg";
import CrickSec3 from "../../../../assets/images/static_pages/diamond_exch.jpg";
import CrickSec4 from "../../../../assets/images/static_pages/play.jpg";
import CrickSec5 from "../../../../assets/images/static_pages/sky_exchange.jpg";
import CrickSec6 from "../../../../assets/images/static_pages/world777.jpg";
import CrickSec7 from "../../../../assets/images/static_pages/lotus.jpg";
import CrickSec8 from "../../../../assets/images/static_pages/goldbet7.jpg";
import WhatsAppIcon from "../../../../assets/images/static_pages/whatsapp.png";
import Partner1 from "../../../../assets/images/static_pages/cricket.jpg";
import Partner2 from "../../../../assets/images/static_pages/football.jpg";
import Partner3 from "../../../../assets/images/static_pages/card_games.jpg";
import Partner4 from "../../../../assets/images/static_pages/teenpati.jpg";
import Partner5 from "../../../../assets/images/static_pages/casino.jpg";
import Partner6 from "../../../../assets/images/static_pages/binary.jpg";
import Partner7 from "../../../../assets/images/static_pages/slot-games.jpg";
import Partner8 from "../../../../assets/images/static_pages/horse_racing.jpg";
import BestIcon1 from "../../../../assets/images/static_pages/da_icon1.png";
import BestIcon2 from "../../../../assets/images/static_pages/da_icon2.png";
import BestIcon3 from "../../../../assets/images/static_pages/da_icon3.png";
import BestIcon4 from "../../../../assets/images/static_pages/da_icon4.png";
import BestIcon5 from "../../../../assets/images/static_pages/da_icon5.png";
import BestIcon6 from "../../../../assets/images/static_pages/da_icon6.png";
import BestIcon7 from "../../../../assets/images/static_pages/da_icon7.png";
import BestIcon8 from "../../../../assets/images/static_pages/da_icon8.png";
import Whychoose from "../../../../assets/images/static_pages/whychoose_bg2.png";
import WaitingIcon1 from "../../../../assets/images/static_pages/dw_icon1.png";
import WaitingIcon2 from "../../../../assets/images/static_pages/dw_icon2.png";
import WaitingIcon3 from "../../../../assets/images/static_pages/dw_icon3.png";
import WaitingIcon4 from "../../../../assets/images/static_pages/dw_icon4.png";
import WaitingIcon5 from "../../../../assets/images/static_pages/dw_icon5.png";
import WaitingIcon6 from "../../../../assets/images/static_pages/dw_icon6.png";
import { addMetaTags } from "../../../../utils/addMetaTags";

const AndarBaharPage = () => {
  useEffect(() => {
    addMetaTags({
      title:
        "Best Andar Bahar Game | Play Andar Bahar Casino Game For Real Money | Dial4Bet",
      content:
        "Explore andar bahar game at Dial4Bet. Play andar bahar live for real cash rewards. Join now for ultimate andar bahar online casino game and earn money while playing! Start your gaming journey with us!",
      canonicalUrl: "https://dial4bet.com/andar-bhar-game",
    });
  }, []);
  console.log("test", document.querySelector("link[rel='canonical']"));
  // document
  //   .querySelector("link[rel='canonical']")
  //   .setAttribute("href", "https://dial4bet.com/cricket-betting/");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <section className="dial_slider_main dial_slider_main_andarbahar">
            <div className="container"></div>
          </section>

          <section className="dial_sec_space dial_sec_two">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Top Betting Id</h2>
              </div>

              <div className="dial_sec_grid_betting_id">
                <ul>
                  <li>
                    <img src={CrickSec2} alt="Fairbet7" />
                  </li>
                  <li>
                    <img src={CrickSec3} alt="Diamond Exch" />
                  </li>
                  <li>
                    <img src={CrickSec4} alt="Play" />
                  </li>
                  <li>
                    <img src={CrickSec5} alt="Sky Exchange" />
                  </li>
                  <li>
                    <img src={CrickSec6} alt="World 777" />
                  </li>
                  <li>
                    <img src={CrickSec7} alt="Lotus" />
                  </li>
                  <li>
                    <img src={CrickSec8} alt="Goldbet7" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_three">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Our Partners</h2>
              </div>

              <div className="dial_partners_grid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Cricket</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner1} alt="Cricket" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Sportsbook</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner2} alt="Football" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Card Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner3} alt="Card Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Teenpatti Multiplayer</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner4} alt="Teen Pati" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Casino</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner5} alt="Casino" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Binary</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner6} alt="Binary" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Slot Games</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner7} alt="Slot Games" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="dial_partner_box">
                      <div className="dial_part_img_box">
                        <h4>Greyhound & Horse Racing</h4>
                        <div className="dial_partnrimg">
                          <img src={Partner8} alt="Horse Racing" />
                        </div>
                      </div>
                      <div className="dial_partinfos">
                        <h4>
                          <Link to="/">Get Id Now</Link>
                        </h4>
                        <Link to="/">
                          <img src={WhatsAppIcon} alt="Whatsapp" />
                          +91-9899998999
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_four">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Why Choose Us</h2>
              </div>

              <div className="dial_whychoose_grid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="whychoose_box">
                      <div className="why_choose_title">
                        <h4>Best Achievements</h4>
                      </div>

                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon1} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Winners</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon2} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24x7 Support</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon3} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              100% Trusted Platform
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon4} alt="Icon" />
                            </span>
                            <span className="dial_icontext">24M+ Users</span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Win Big</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="whychoose_box whychoose_box2">
                      <div className="why_choose_title">
                        <h4>Why Choose Us</h4>
                      </div>
                      <div className="why_choose_grid">
                        <ul>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon5} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Instant Withdrawal
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon6} alt="Icon" />
                            </span>
                            <span className="dial_icontext">Best Platform</span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon7} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Players Online
                            </span>
                          </li>
                          <li>
                            <span className="dial_wc_icon">
                              <img src={BestIcon8} alt="Icon" />
                            </span>
                            <span className="dial_icontext">
                              Working Since 2010
                            </span>
                          </li>
                        </ul>
                        <div className="dial_choose_btn">
                          <Link to="/">Click Get Your ID</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dial_sec_space dial_sec_five">
            <div className="container">
              <div className="dial_ads_infos">
                <h3>Fast Withdrawal Get 15% cashback Betting Id</h3>
                <p>
                  This is a very easy way to withdraw cash and we are providing
                  the best offer for new users.
                </p>
                <div className="ads_btns">
                  <Link to="/">+9198888899999</Link>
                  <Link to="/" className="dial_booknow">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_one">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Andar Bahar</h2>
              </div>
              <div className="dial_first_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_img_block">
                      <img src={AndarBahar} alt="Cricket Image" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="dial_right_infos">
                      <h1>Andar Bahar Game: A Classic Indian Casino Game</h1>
                      <p>
                        The <b>Andar Bahar game</b> is a traditional Indian card
                        game that has gained immense popularity in recent years.
                        Known for its simplicity and fast-paced action, this
                        game has become a favorite among casino enthusiasts
                        worldwide. Whether you're new to the game or a seasoned
                        player, understanding the nuances of Andar Bahar can
                        enhance your gaming experience.
                      </p>

                      <p>
                        Dial4bet brings the thrill of the{" "}
                        <b>Andar Bahar game</b> directly to your fingertips,
                        offering you the chance to experience this unique game
                        from the comfort of your home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dial_first_block dial_first_block_more">
                <h2>
                  Andar Bahar Online: Bringing the Game to Your Fingertips
                </h2>
                <p>
                  With the rise of online casinos,
                  <b>Andar Bahar online</b> has become accessible to a global
                  audience. Playing Andar Bahar online offers the convenience of
                  enjoying this classic game from the comfort of your home.
                  Online platforms provide a variety of versions and stakes,
                  catering to both beginners and high rollers. The digital
                  format also allows for additional features like live dealer
                  games, which bring the authentic casino experience to your
                  screen.
                </p>
                <p>
                  Whether you're a seasoned player or just starting your online
                  casino journey, Dial4Bet allows you to{" "}
                  <b>play Andar Bahar online</b> at your own pace and comfort
                  level.
                </p>
                <h2>Variations of Andar Bahar Casino Game</h2>
                <p>
                  While the traditional <b>Andar Bahar casino game</b>remains
                  popular, several variations have emerged to add excitement and
                  new challenges to the game. Some online casinos offer versions
                  with side bets, giving players the chance to win bigger
                  payouts. Common side bets include guessing the suit of the
                  joker card or predicting the number of cards dealt before the
                  match occurs.
                </p>
                <h3>Popular Variations</h3>
                <ul className="gameList">
                  <li>
                    <b>Live Andar Bahar:</b> In <b>Andar Bahar live</b>games, a
                    real dealer manages the game in real-time via live
                    streaming. This version provides an immersive experience,
                    replicating the atmosphere of a physical casino.
                  </li>
                  <li>
                    <b>Speed Andar Bahar:</b> This <b>Andar Bahar game</b>
                    variant speeds up the dealing process, making it perfect for
                    players who prefer quick rounds.
                  </li>
                  <li>
                    <b>Andar Bahar with Side Bets:</b>{" "}
                    <b>Andar Bahar casino game</b>offers additional betting
                    options beyond the basic Andar and Bahar bets.
                  </li>
                </ul>
                <h2>Andar Bahar Live: The Ultimate Casino Experience</h2>
                <p>
                  For players seeking an authentic casino atmosphere,{" "}
                  <b>Andar Bahar live</b>games are the perfect choice. Live
                  Andar Bahar games are hosted by professional dealers in
                  real-time, providing the same level of excitement as playing
                  in a physical casino. Players can interact with the dealer and
                  other participants, enhancing the social aspect of the game.
                  This <b>Andar Bahar casino game</b>version is ideal for those
                  who enjoy the communal experience of casino gaming.
                </p>
                <p>
                  At Dial4Bet , we offer a top-notch platform to{" "}
                  <b>play Andar Bahar online.</b>Our Andar Bahar casino features
                  a wide range of Andar Bahar games, including live dealer
                  versions and exciting variations with side bets. With
                  user-friendly interfaces and high-quality graphics, we ensure
                  that your Andar Bahar live gaming experience is both enjoyable
                  and rewarding.
                </p>
                <h2>Andar Bahar Card Game Online: Tips and Strategies</h2>
                <p>
                  While the <b>Andar Bahar card game online</b>is largely a game
                  of chance, there are a few tips and strategies that can help
                  improve your odds of winning. Here are some recommendations
                  for playing the <b>Andar Bahar card game online:</b>
                </p>
                <ul className="gameList">
                  <li>
                    <b>Understand the Odds:</b>Familiarize yourself with the
                    basic odds of the <b>Andar Bahar casino game.</b>Typically,
                    Andar has a slightly higher probability of winning compared
                    to Bahar, especially if the first card is placed in the
                    Andar pile.
                  </li>
                  <li>
                    <b>Manage Your Bankroll:</b>Set a budget for your{" "}
                    <b>Andar Bahar live</b>gaming session and stick to it. Avoid
                    chasing losses and know when to walk away.
                  </li>
                  <li>
                    <b>Practice with Free Games: </b>Our casino offers free
                    versions of
                    <b>Andar Bahar casino.</b>Use these to practice and develop
                    your strategies without risking real money
                  </li>
                </ul>
                <h3>Andar Bahar Casino Game: Enhancing Your Experience</h3>
                <p>
                  The <b>Andar Bahar casino</b>is not just about winning but
                  also about enjoying the process. Engage with the game,
                  appreciate the simplicity and excitement, and remember to
                  gamble responsibly. Whether you are playing at a physical
                  casino or an online platform like Dial4Bet , the key to a
                  great gaming experience is to have fun and{" "}
                  <b>play Andar Bahar online</b>responsibly.
                </p>
                <p>
                  The Andar Bahar game is a timeless classic that continues to
                  captivate players around the world. Its simplicity, fast-paced
                  action, and the thrill of predicting the outcome make it a
                  favorite among casino enthusiasts. Whether you're playing{" "}
                  <b>Andar Bahar online,</b>enjoying a live Andar Bahar session,
                  or exploring different variations at an{" "}
                  <b>Andar Bahar casino, </b>this game offers endless
                  excitement.
                </p>
              </div>
            </div>
          </section>

          <section className="dial_sec_space dial_sec_six">
            <div className="container">
              <div className="dial_sec_title">
                <h2>Waiting for Booking</h2>
              </div>
              <div className="dial_sub_text">
                We have some great and good features due to which you will feel
                good in joining us. You will get hundred percent support from
                our side.
              </div>

              <div className="dial_waiting_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon dial_waiting_icon_small">
                        <img src={WaitingIcon1} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Safe & Secure</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon2} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Active Users</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon3} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Replay on Whatsapp</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_wgrid_img">
                      <img src={Whychoose} alt="WhychooseImage" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon4} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>Branches</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon  dial_waiting_icon_small">
                        <img src={WaitingIcon5} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>24/7 Hours Services</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>

                    <div className="dial_waiting_box">
                      <div className="dial_waiting_icon">
                        <img src={WaitingIcon6} alt="Icon" />
                      </div>
                      <div className="dial_waiting_infos">
                        <h4>On Time & Fast Delivery</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default AndarBaharPage;
